import React     from "react";
import PropTypes from "prop-types";

export const ErrorContainer = props => (
    <div className={props.small? "alert alert-danger small" : "alert alert-danger"} role="alert">
        {props.showClose ?
            <button type="button" className="btn close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            : ""
        }
        {
            props.arErrors.map((error, index) => (
                    <p className={"mb-0"} key={index}>{error}</p>
                ),
            )
        }
    </div>
);

ErrorContainer.propTypes = {
    arErrors: PropTypes.array,
    showClose: PropTypes.bool,
    small: PropTypes.bool,
};

import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorContainer } from "./ErrorContainer";
import { TextLoader } from "./TextLoader";

const axios = require('axios').default;

/**
 * компонент для поиска только по связанным с Persons сущностям - Students, Wishes, Teachers, Users
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchInputGroup = props => {

    const queryInputGroupRef = useRef(null);
    const useFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus();
        };

        return [htmlElRef, setFocus];
    };

    const [queryInputRef, setQueryInputFocus] = useFocus();
    const [query, setQuery] = useState("");


    useEffect(() => {
        if (!props.isLoading) {
            setQueryInputFocus();
        }
    }, [props.isLoading]);

    useEffect(() => {
        document.body.addEventListener('click', clearSearchResults);
        return () => {
            document.body.removeEventListener('click', clearSearchResults);
        };
    }, []);

    const clearSearchResults = (event) => {
        if (queryInputGroupRef && queryInputGroupRef.current.contains(event.target)) {
            return false;
        }
        setQuery("");
        if (typeof props.onChange === "function") {
            props.onChange("");
        }
        if (typeof props.onClearSearchResult === "function") {
            props.onClearSearchResult(true);
        }
    };

    function OnChange(e) {
        setQuery(e.target.value);
        if (typeof props.onChange === "function") {
            props.onChange(e.target.value);
        }
    }

    return (
        <div className="input-group"
            ref={queryInputGroupRef}>
            {
                props.isLoading ?
                    <div className={"form-control text-start text-muted"}>
                        <TextLoader text={"идёт поиск"} />
                    </div>
                    :
                    <input className={"form-control"}
                        type="text"
                        ref={queryInputRef}
                        disabled={props.isLoading}
                        value={query}
                        onChange={OnChange}
                        onKeyDown={props.onKeyDown}
                        placeholder={props.placeholder}
                        aria-label={props.placeholder} />
            }
            <button className="btn btn-outline-secondary"
                onClick={props.onSearch}
                type="button"><i className="fas fa-search"></i>
            </button>
            <button className="btn btn-outline-secondary"
                onClick={clearSearchResults}
                type="button">X
            </button>
        </div>
    );
};

SearchInputGroup.propTypes = {
    disabled: PropTypes.bool,
    query: PropTypes.string,
    queryInputGroupRef: PropTypes.func,
    queryInputRef: PropTypes.func,
    onSearch: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClearSearchResult: PropTypes.func,
    placeholder: PropTypes.string,
    isLoading: PropTypes.bool,
};

import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes                                      from "prop-types";
import {ErrorContainer}                               from "../dom/ErrorContainer";
import {TextLoader}                                   from "../dom/TextLoader";
import {SearchInputGroup}                             from "../dom/SearchInputGroup";
import {PersonLoader}                                 from "../helpers/PersonLoader";
import {DropList}                                     from "../dom/DropList";
import {KEYS}                                         from "../enums/KeysIndexes";
import {Entities}                                     from "../enums/Entities";

const axios = require('axios').default;

/**
 * компонент для поиска только по связанным с Persons сущностям - Students, Wishes, Teachers, Users
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PersonsSearchDropList = props => {

    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [persons, setPersons] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [resultCursor, setResultCursor] = useState(0);
    const [currentItemClicked, setCurrentListItemClicked] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (!query) {
            setPersons([]);
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            PersonLoader(query, setPersons, setErrors, setLoading, setNoResults);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const setResultsActiveItem = (keyCode) => {
        if (keyCode === KEYS.DOWN && persons.length) {
            setResultCursor(prevState =>
                prevState < persons.length ? prevState + 1 : prevState,
            );
        } else if (keyCode === KEYS.UP && persons.length) {
            setResultCursor(prevState =>
                prevState > 0 ? prevState - 1 : prevState,
            );
        } else if (keyCode === KEYS.ENTER && persons.length) {
            setCurrentListItemClicked(true);
        }
    };

    const getListItemView = (item) => {
        let personString = `${item.fio}`;
        if (item.birthdateDisplay) {
            personString += `, ДР ${item.birthdateDisplay}`;
        }
        return personString;
    };

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            <SearchInputGroup
                disabled={isLoading}
                isLoading={isLoading}
                value={query}
                onChange={(value) => setQuery(value)}
                onClearSearchResult={() => setNoResults(false)}
                onKeyDown={e => setResultsActiveItem(e.keyCode)}
                placeholder={props.placeholder ? props.placeholder : "быстрый поиск"}/>
            <DropList
                resultCursor={resultCursor}
                currentItemClicked={currentItemClicked}
                noResults={noResults}
                getListItemView={getListItemView}
                results={persons}
                entity={Entities.PERSONS}/>
        </Fragment>
    );
};

PersonsSearchDropList.propTypes = {
    placeholder: PropTypes.string,
};

import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";

export const TextLoader = props => {
    const [dots, setDots] = useState("");
    useEffect(() => {
        let interval = null;
            interval = setInterval(() => {
                if (dots.length > 3) {
                    setDots("");
                } else {
                    setDots(dots => dots + ".");
                }
            }, 300);
        return () => clearInterval(interval);
    }, [dots]);

    return (
        <Fragment>{props.text}{dots}</Fragment>
    );
};

TextLoader.protoTypes = {
    text: PropTypes.string,
};

import {Pages}                   from "./Pages";
import React                     from 'react';
import ReactDOM                  from "react-dom";
import {PersonDependentElements} from "../enums/PersonDependentElements";
import {PersonsSearchDropList}   from "../components/PersonsSearchDropList";

export class Persons extends Pages {
    constructor(params) {
        super(params);
    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
        this.data = window.diaryJsData;

        ReactDOM.render(
            <PersonsSearchDropList
                placeholder={"искать по списку физлиц"}/>,
            document.getElementById('list-search-form-component'),
        );

    }
}

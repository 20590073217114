export const Entities = {
    GROUPS: 'groups',
    PROGRAMS: "programs",
    TEACHER: "teachers",
    CONTACT: "contacts",
    USER: "users",
    PERSONS: "persons",
    CERTIFICATES: "certificates",
    CALCS: "calcs",

};

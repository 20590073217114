import {useEffect} from "react";

const axios = require('axios').default;

export const PersonLoader = (query, setPersons, setErrors, setLoading, setNoResults) => {
    let queryLikeLength = 2;
    let queryConditions = {};
    queryConditions.filter = `Y`;

    if (query.length <= queryLikeLength) {
        queryConditions.f = query;
    } else {
        let words = query.split(" ");
        if (words.length > 1) {
            queryConditions["f LIKE"] = `%${words[0]}%`;
            queryConditions["i LIKE"] = `%${words[1]}%`;
            if (words.length > 2) {
                queryConditions["o LIKE"] = `%${words[2]}%`;
            }
        }else{
            queryConditions["f LIKE"] = `%${query}%`;
        }
    }

        setLoading(true);
        axios.get('/api/persons/', {
            params: queryConditions,
        })
             .then((response) => {
                 setPersons(response.data);
                 setLoading(false);
                 if (response.data.length === 0) {
                     setNoResults(true);
                 }
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
                 setLoading(false);
             });

};

import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes                                      from "prop-types";
import {KEYS}                                         from "../enums/KeysIndexes";

export const DropList = props => {

    const listItemsCount = 30;
    let moreThenTen = false;

    const resultLinkRef = useRef(null);

    useEffect(() => {
        if (props.currentItemClicked && resultLinkRef.current) {
            resultLinkRef.current.click();
        }
    }, [props.currentItemClicked]);

    return (
        <Fragment>
            {
                props.results.length ?
                    <div className={"list-group shadow entities-drop-list"}>
                        {
                            props.results.map((item, index) => {
                                    if (index > (listItemsCount - 1)) {
                                        moreThenTen = true;
                                        return false;
                                    }
                                    let activeRef = (index === props.resultCursor - 1) ? resultLinkRef : null;
                                    let activeClass = (index === props.resultCursor - 1) ? "active" : "";
                                    return (
                                        <a key={item.ID || item.id || index}
                                           ref={activeRef}
                                           href={`/${props.entity}/${item.ID || item.id}/`}
                                           className={`list-group-item list-group-item-action ${activeClass}`}
                                           title={"выбрать"}>
                                            {props.getListItemView(item)}
                                        </a>
                                    );
                                },
                            )
                        }
                        {moreThenTen ?
                            <a className={'list-group-item list-group-item-action disabled text-center text-danger small'}>
                                Найдено больше чем {listItemsCount}, уточните параметры поиска
                            </a>
                            :
                            ""
                        }
                    </div>
                    :
                    <Fragment>
                        {props.noResults ?
                            <div className={"list-group shadow entities-drop-list"}>
                                <a className={'list-group-item list-group-item-action disabled text-center text-danger small'}>
                                    Ничего не найдено!
                                </a>
                            </div>
                            :
                            ""
                        }
                    </Fragment>
            }
        </Fragment>
    );
};

DropList.protoTypes = {
    results: PropTypes.array,
    noResults: PropTypes.bool,
    resultCursor: PropTypes.numeric,
    currentItemClicked: PropTypes.bool,
    getListItemView: PropTypes.func,
};
